// ** Reducers Imports
import propertyOccupationCalendar from "@components/shared/properties/property-occupation/store";
import checkInOutCalendar from "@components/shared/dashboards/check-in-out/store";
import notificationTemplate from "@pages/settings/notification-templates/store";
import auth from "./authentication";
import layout from "./layout";
import navbar from "./navbar";

const rootReducer = {
  auth,
  navbar,
  layout,
  checkInOutCalendar,
  propertyOccupationCalendar,
  notificationTemplate,
};

export default rootReducer;

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const notificationTemplateSlice = createSlice({
  name: "notificationTemplate",
  initialState: {
    // Notification templates
    notificationTemplateMetaData: {},
    notificationTemplateData: {},
    filteredNotificationTemplateData: [],
    selectedNotificationItem: {},
    destinationsNotificationOption: [],
    selectedNotificationService: "",
    selectedNotificationDestination: "",
  },
  reducers: {
    handleSelectedNotificationItem: (state, action) => {
      state.selectedNotificationItem = action.payload.selectedItem;
    },
    handleNotificationSearch: (state, action) => {
      const tempData = Object.values(state.notificationTemplateData).flat();
      state.filteredNotificationTemplateData = tempData.filter((item) =>
        item.name.startsWith(action.payload.query)
      );
    },
    handleSelectNotificationService: (state, action) => {
      state.selectedNotificationService = action.payload.service;
    },
    handleSelectNotificationDestination: (state, action) => {
      state.selectedNotificationDestination = action.payload.destination;
    },
  },
  extraReducers: () => {},
});

export const {
  handleSelectedNotificationItem,
  handleNotificationSearch,
  handleSelectNotificationService,
  handleSelectNotificationDestination,
} = notificationTemplateSlice.actions;

export default notificationTemplateSlice.reducer;

// ** React Imports
import { useReducer, createContext } from "react";

// ** Create Context
const AttachmentsCtx = createContext();

const initialAttachmentsState = [
  {
    id: 0,
    name: "",
    file: null,
  },
];

// attachmentsReducer function
const attachmentsReducer = (state, action) => {
  switch (action.type) {
    case "add_attachment": {
      return [
        ...state,
        {
          id: action.id,
          name: "",
          file: null,
        },
      ];
    }
    case "change_attachment_name": {
      return state.map((att) => {
        if (att.id === action.attachment.id) {
          return {
            ...att,
            name: action.attachment.name,
          };
        } else {
          return att;
        }
      });
    }
    case "change_attachment_file": {
      return state.map((att) => {
        if (att.id === action.attachment.id) {
          return {
            ...att,
            file: action.attachment.file,
          };
        } else {
          return att;
        }
      });
    }
    case "delete_attachment": {
      return state.filter((att) => att.id !== action.id);
    }
    case "reset_attachments_state": {
      return [];
    }
  }
};

const AttachmentsContext = ({ children }) => {
  // ** Reducer
  const [attachmentsState, dispatch] = useReducer(
    attachmentsReducer,
    initialAttachmentsState
  );

  const handleAddAttachment = () => {
    dispatch({
      type: "add_attachment",
      id: Date.now(),
    });
  };

  const handleChangeAttachmentFile = (attachment) => {
    dispatch({ type: "change_attachment_file", attachment });
  };

  const handleChangeAttachmentName = (attachment) => {
    dispatch({ type: "change_attachment_name", attachment });
  };

  const handleDeleteAttachment = (id) => {
    dispatch({ type: "delete_attachment", id });
  };

  const handleResetAttachmentsState = () => {
    dispatch({ type: "reset_attachments_state" });
  };

  return (
    <AttachmentsCtx.Provider
      value={{
        attachmentsState,
        handleAddAttachment,
        handleChangeAttachmentFile,
        handleChangeAttachmentName,
        handleDeleteAttachment,
        handleResetAttachmentsState,
      }}
    >
      {children}
    </AttachmentsCtx.Provider>
  );
};

export { AttachmentsContext, AttachmentsCtx };

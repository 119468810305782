import { AbilityBuilder, createMongoAbility } from "@casl/ability";

const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

const appType = process.env.REACT_APP_TYPE;

const userDataPermission =
  JSON.parse(localStorage.getItem("userData"))?.permissions || [];

if (appType === "admin") {
  userDataPermission.map(
    (item) => item && can(item.split(".")[1], item.split(".")[0])
  );
} else {
  can("manage", "all");
}

export default build();

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const appCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    filteredEvents: [],
    selectedEvent: {},
    selectedCalendars: ["check_in", "check_out"],
  },
  reducers: {
    handleFetchEvents: (state, action) => {
      if (action.payload) {
        state.events = action.payload;
      }
    },
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
    handleFilterCalendar: (state, action) => {
      if (action.payload === "view_all") {
        if (state.selectedCalendars.length === 2) {
          state.selectedCalendars = [];
        } else {
          state.selectedCalendars = ["check_in", "check_out"];
        }
      }

      if (state.selectedCalendars.includes(action.payload)) {
        action.payload === "check_in"
          ? (state.selectedCalendars = ["check_out"])
          : (state.selectedCalendars = ["check_in"]);
      } else if (
        action.payload === "check_in" ||
        action.payload === "check_out"
      ) {
        state.selectedCalendars.push(action.payload);
      }

      state.filteredEvents = state.events.filter((item) =>
        state.selectedCalendars.find((it) => it === item.calendar)
      );
    },
  },
});

export const { selectEvent, handleFetchEvents, handleFilterCalendar } =
  appCalendarSlice.actions;

export default appCalendarSlice.reducer;

// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

const initialUserToken = () => {
  const item = window.localStorage.getItem("token");
  //** Parse stored json or if none return initialValue
  return item ? item : {};
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: initialUser(),
    token: initialUserToken(),
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = {
        ...action.payload.userData,
      };
      state.token = action.payload.token;
      // ** Add to user, accessToken & refreshToken to localStorage
      localStorage.setItem("userData", JSON.stringify(state.userData));
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("loggedIn", true);
    },
    handleLogout: (state) => {
      state.userData = {};
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("token");
      localStorage.setItem("loggedIn", false);
    },
    handleUpdateUser: (state, action) => {
      const { avatar, name, email, phone } = action.payload.userData;

      state.userData.avatar = avatar;
      state.userData.name = name;
      state.userData.email = email;
      state.userData.phone = phone;
      localStorage.setItem("userData", JSON.stringify(state.userData));
    },
  },
});

export const { handleLogin, handleLogout, handleUpdateUser } =
  authSlice.actions;

export default authSlice.reducer;

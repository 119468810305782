// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";

export const propertyOccupationCalendarSlice = createSlice({
  name: "appCalendar",
  initialState: {
    events: [],
    selectedEvent: {
      contract_details: {
        rental_reference: ""
      },
      tenant: ""
    },
  },
  reducers: {
    handleFetchEvents: (state, action) => {
      if (action.payload) {
        state.events = action.payload;
      }
    },
    handleSelectEvent: (state, action) => {
      state.selectedEvent = action.payload;
    },
  },
});

export const { handleSelectEvent, handleFetchEvents } =
  propertyOccupationCalendarSlice.actions;

export default propertyOccupationCalendarSlice.reducer;
